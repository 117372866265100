import React from "react";
import data from './data'
import './Work.css';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useParams
} from "react-router-dom";

function Work(){
  return(
    <Switch>
      <Route exact path="/" children={<Gallery/>}/>
      <Route path="/:id" children={<Expand/>}/>
    </Switch>
  )
}

function Gallery(){
  return(
    <div id="gridbox">
      {data.map(i => (
        <div className="card"  style={{backgroundImage : `url(${i.thumb})`}} key={i.id+10}>
        <Link key={i.id} to={{pathname: `/${i.id}`}}>
            <div className="imgbox"></div>
            <div className="multiply overlay"></div>
            <div className="text overlay">{i.text}</div>
        </Link>
        </div>
      ))}
    </div>
  );
}


function Expand(){
  let history = useHistory();
  let {id} = useParams();
  let i = data[parseInt(id)];
  let back = e => {
    e.stopPropagation();
    history.goBack();
  };
  //let photos = i.images; 
  return (
      <div className="expanded">

          <div className={i.style} >
            {i.images.map( src => (
              <img src={src.image} alt="" key={i.id}/>))}
          </div>

          <div className="information">
              <div className="header">
                  <div className="title">{i.text}</div>
                  <div className="back" onClick={back}> Back </div>
              </div>
              <div className="description">{i.description}</div>
          </div>
      </div>
  );
}


export default Work;
