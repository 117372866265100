import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from "react-router-dom";
import './index.css';
import Layout from './Layout';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <HashRouter>
        <Route path="/" component={Layout} />
    </HashRouter>,
    document.getElementById('root')
);
serviceWorker.unregister();
