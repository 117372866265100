const data = [
  { id: 0,
    thumb: require('./assets/thumb1.jpg'),
  images: [{image: require('./assets/asset01.jpg')}, {image: require('./assets/asset02.jpg')}, {image: require('./assets/asset03.png')}],
  text: "wharton marketing materials",
  description: "A selection of some materials I designed during my time at Wharton. These were mostly made for the salesmen to use in their day to day tasks.",
  style: "imgdiv"},

  { id: 1,
  thumb: require('./assets/thumb2.jpg'),
  images: [{image: require('./assets/asset20.jpg')}, {image: require('./assets/asset21.jpg')}, {image: require('./assets/asset22.jpg')}, {image: require('./assets/asset23.jpg')}, {image: require('./assets/asset24.jpg')}] ,
  text: "wharton rental catalog",
  description: 'An 11" x 25.5" trifold catalog made to showcase the wide range of machines we rented out at Wharton. For this project I worked off a design made by the previous graphic designer at Wharton.',
  style:"imgdiv catalog"},

  { id: 2, thumb: require('./assets/thumb3.jpg'),
  images: [{image: require('./assets/asset3.jpg')}, {image: require('./assets/asset301.jpg')}],
  text: "wiccomico public library flyers",
  description: "Promotional flyers made for the Wiccomico Public Library. Quick turnaround time.",
  style:"imgdiv"},

  { id: 3,
  thumb: require('./assets/thumb4.jpg'),
  images: [{image: require('./assets/asset40.jpg')}, {image: require('./assets/asset41.jpg')}, {image: require('./assets/asset42.jpg')}, {image: require('./assets/asset43.jpg')}],
  text: "social media assets",
  description: "A selection of some of the social media assets I made when I was working with the Wicomico Public Library. On top of having to make these images I would also write copy for the posts that would accompany these images.",
  style: "imgdiv"},

  { id: 4,
  thumb: require('./assets/asset5.png'),
  images: [{image: require('./assets/asset5.png')}],
  text: "ligma glass sticker",
  description: "Sticker design made for a friend's glass blowing business. Drawn in CSP then taken into PS to do typography/post-processing.",
  style: "imgdiv singleimg"},

  { id: 5,
  thumb: require('./assets/thumb6.jpg'),
  images: [{image: require('./assets/asset60.jpg')}, {image: require('./assets/asset61.jpg')}, {image: require('./assets/asset62.jpg')}, {image: require('./assets/asset63.jpg')}, {image: require('./assets/asset64.jpg')}],
  text: "wharton trowel catalog",
  description: "Catalog designed to quickly showcase the various *CONMEC* trowels available to purchase through Wharton.",
  style: "imgdiv catalog"},

  { id: 6,
  thumb: require('./assets/thumb7.jpg'),
  images: [{image: require('./assets/asset70.jpg')}, {image: require('./assets/asset71.jpg')}, {image: require('./assets/asset72.jpg')}],
  text: "film photography",
  description: "A few photographs taken to practice photography on something other than a phone.",
  style: "imgdiv"},

  { id: 7,
  thumb: require('./assets/thumb8.png'),
  images: [{image: require('./assets/asset8.png')}],
  text: "'smokes' 3d art print",
  description: "A piece done in order to practice using *Blender* in my designs/projects.",
  style: "imgdiv"},

  { id: 8,
    thumb: require('./assets/thumb9.jpg'),
    images: [{image:require('./assets/asset9.jpg')}],
    text:  "'SLACKER' poster",
  description: "Poster for the film *Slacker* done as personal practice to test out some new PS tricks I was learning.",
  style: "imgdiv"}
];
export default data;
