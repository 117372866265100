import React from 'react';
import './Layout.css';
import Work from './Work'
import About from './About'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";

function Layout(){
    return (
      <div className="container">
      <Router>
        <div className ="top horizontal banner">
          <div id="top">
            <p className="desk">‎ JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz JOSEdiaz</p>
            </div>
            <p className="mob"> JOSEdiaz </p>

        </div>
          <NavLink activeClassName='active about-active'to="/about"><div className ="left vertical banner">
            <div id="left">
              <p className="desk">‎ click for about click for about click for about click for about click for about click for about</p>
              </div>
              <p className="mob">about  </p>

          </div>
          </NavLink>
          <NavLink activeClassName='active work-active' exact to="/"><div className ="right vertical banner">
            <div id="right">
              <p className="desk">‎ click for work click for work click for work click for work click for work click for work </p>
              </div>
              <p className="mob">‎work </p>

          </div></NavLink>

          <div className ="bottom horizontal banner">
            <NavLink to={require('./assets/resume.pdf')} target="_blank"><div id="bottom">
              <p className="desk">‎ click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume click for resume</p>
              </div>
              <p className="mob"> resume </p></NavLink>

          </div>

          <div className="other">
                <Switch>
                  <Route exact path="/"> <Router> <Work/> </Router> </Route>
                  <Route path="/about"> <About/> </Route>
                </Switch>
          </div>


</Router>
      </div>
  );
  }

export default Layout;
