import React from 'react';
import './About.css';
import logo from './assets/logo.png'
import placeholder from './assets/placeholder.jpg'

const About = () =>{
  return(
    <div className="wrapper">
      <div className="about">
          <img class ="about-img" src={placeholder} alt=""></img>
          <p> Jose Diaz is a graphic designer working on the Eastern coast of the US. He's usually hired to create graphics for physical and online advertising but he has also worked on projects involving UI/UX, video editing, photography, and a bit of front end web dev. In order to learn how to use Adobe XD jose decided to design and prototype this site all on his own! Was it hard??? A little bit. Was it worth it?? We'll see.... :)</p>
      </div>
      <img className="logo" src={logo} alt=""></img>
    </div>
  )
}

export default About;
